@import 'src/css/master.scss';

.DashboardNavDrawer {
    position: relative;
    left:-4%;
    top:-20px;
    width:calc(#{$NavDrawer-Toggle-Width} - 8%);
    // max-width: $NavDrawer-Toggle-Width;
    box-shadow: $NavDrawer-Toggle-Box-Shadow;
    margin:0 0 15px auto;
    // overflow: hidden;

    &:last-child {
        margin-bottom:0;
    }

    .DrawerToggle {
        width:100%;
        background-color: $NavDrawer-Toggle-Background-Color;
        text-transform: $NavDrawer-Toggle-Text-Transform;
        border: $NavDrawer-Toggle-Border;
    }
    
    .ToggleTitle {
        h3 {
            line-height: $NavDrawer-Toggle-Line-Height;
            color: $NavDrawer-Toggle-Text-Color;
            text-align: left;
            font-weight: 600;
            font-size:12px;
            padding:0 0 0 1rem;
            margin:0;
        }
    }

    .DashboardNavChevron {
        position:relative;
        right:10px;
        top:10px;
    }

    .DrawerContents {
        .IAAIncentiveCard {
            margin-bottom: 1.5rem;
        }

        .ToggleTitle {
            h3 {
                padding:0 0 0 2rem;
                font-weight: normal;
                font-size:14px;
            }
        }
    }

    .DashboardNavCounter {
        position: relative;
        font-family: $Font-family-1;
        color: $white;
        background-color: $neutrals-1;
        width: 1.2rem;
        height: 1.2rem;
        border-radius: 0.75rem;
        top: 0.75rem;
        right:0.75rem;
        font-size:12px;
    }

    .DashboardNavDrawer {
        width:100%;
        top:0;
        left:0;
    }
}

.ProgressSubdrawer {
    .ToggleTitle {
        text-transform: none;
        text-align: left;
    }
}

.mobileClass {
    .DashboardNavDrawer {
        .DrawerToggle {
            width: 100%;
        }
    }
}