@import 'src/css/master.scss';

.DesktopDashboard {
    width: 80%;
    margin: 0 auto;
    text-align: center;

    .papers {
        margin: 5rem 0 2rem 0;
    }
    h1 {
        font-size: 2rem;
        margin-bottom: 1.75rem;
    }

    .ready-submit-text {
        text-align: justify;
    }
}

.mobileClass {
    .DesktopDashboard {
        padding: 2rem 0;

        .papers {
            margin: 0 0 2rem 0;
        }
        .ready-submit-text {
            margin-bottom: 0;
        }
    }
}