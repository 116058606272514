@import 'src/css/master.scss';

.ApplicationStepNavLinkWrapper {
    .ApplicationStepNavLink {
        height: 1rem;
        
        .ApplicationStepNavOverscore {
            border: solid 3px $neutrals-3;
            border-radius: 4px; 
            margin-bottom: 0.25rem;   
        }
    }

    .activeStep {
        .ApplicationStepNavOverscore {
            border-color: $reds-1;
        }

        color: $reds-1;
        &:hover {
            color: $reds-1;
        }
    }

    &:hover {
        text-decoration: none;
    }
}
