@import 'src/css/customizations/primitives/colors.scss';
@import 'src/css/customizations/primitives/fonts.scss';

$Body-background-color: $neutrals-4;
$Body-text-color: $neutrals-2;

// rem is based on this. Any other case where we sizing text-related stuff, please use rem not px 
$Body-font-size: 16px;
$Body-font-family: $Font-family-1;

$Default-padding-top: 2rem;
$Default-padding-bottom: 2rem;
$Default-padding-left: 2rem;
$Default-padding-right: 2rem;

$Default-link-color: $reds-1;
$Default-link-decoration: none;
$Default-hover-link-color: $reds-1;
$Default-hover-link-decoration: underline;

$Container-section-background-color-1: $Body-background-color;
$Container-section-padding-default: 2rem 1rem;


