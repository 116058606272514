@import 'src/css/master.scss';

.DashboardChargerHead {
    background-color: $orange-2;
    color: $white;
    max-width: $NavDrawer-Toggle-Width;
    margin-left:auto;
    padding: 2rem 1.5rem 2rem 1.5rem;

    @media(max-width:767px) {
        margin:0 auto;
    }

    h2.ChargerName {
        font-weight: $Strong-font-weight;
        font-size:14px;
        color: $white;
        margin-top: -25px;
    }
    .ChargerOwnerLocation {
        margin: 1.5rem 0;
        font-size:14px;
        margin-top: 0px;
    }

    .ChargerApplicationProgressBar {
        .ProgressBarLegend {
            padding: 0.5rem 0;
            font-size:12px;
        }
    }
}

.mobileClass {
    .DashboardChargerHead {
        width: 100%;
        max-width:none;
    }

    .DashboardNavDrawer {
        width:95%;
        margin:0 auto 15px;
        left:0;

        .IAAIncentiveCard {
            max-width:100%;
        }
    }

    .right-button {
        padding-right:0;
    }

    .IAABackNext {
        padding-bottom: 10px;
    }
}