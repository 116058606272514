@import "src/css/master.scss";

.Header {
	background-color: $Header-background-color;
	color: $Header-color;
	border-bottom: $Header-border-bottom;

	a {
		color:$Header-link-color;

    &:hover {
      color: $white;
    }

    // The default box shadow on focus "sticks out" below the header
    &:focus {
      box-shadow: none;
    }
	}

  .mobile-container {
    width: 100vw;
    margin: 0 -1rem;
    margin-top: -20px;
  }

  .navbar-nav {
    .nav-item {
      .nav-link {
        // padding: 2rem 0.5rem;
        @media(max-width: 767px) {
          padding: 0.5rem;
        }
        &:hover {
          background-color: lighten($Header-background-color, 10%);
        }
      }
    }
  }

  .navbar-brand {
    font-size: 1rem;
    font-weight: 300;
  }

  .dropdown-menu {
    border-radius: 0;
    background-color: $white;

    .dropdown-item {
      background-color: $white;
      &, &:hover, &:focus {
        background-color: $white;
        color: $purples-1;
      }

      padding-left: 2rem;
    }
  }

  .contact-us-text {
    color: $purples-1;
  }

  .dropdown-toggle::after {
    color: $purples-1;
  }

  // Fancier styles for desktop
  @media (min-width: 768px) {
    .dropdown-menu {
      background-color: $white;
      border: 1px solid $neutrals-3;
      padding: 0;

      .dropdown-item {
        padding: 0.5rem 1.5rem;
        background-color: $white;
        &, &:hover, &:focus {
          background-color: $white;
          color: $neutrals-2;
        }

        &:hover, &:focus {
          color: $purples-1;
        }

        border-bottom: 1px solid $neutrals-3;

        &:last-child {
          border-bottom: none;
        }
      }
    }
  }

  .dashboard-link {
    text-transform: uppercase;
    padding-right: 2rem;
  }

  .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(159, 27, 150, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");

  }
}
