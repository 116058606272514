@import 'src/css/master.scss';

.IAARadio {
    .inputLegend {
        margin-bottom: $RadioButton-Padding;
    }

    label {
        padding-left: $RadioButton-Label-Padding;
    }

    // @TODO don't use rem's to position dot
    input[type="radio"] {
        -webkit-appearance: none;
        display: inline-block;
        position: relative;
        border: none;
        &:before {
            content: "";
            display: inline-block;
            width: 1rem;
            height: 1rem;
            border-style: solid;
            border-width: 0.1rem;
            border-color: $neutrals-2;  
            border-radius: 50%;
        }
        &:checked {
            color: $reds-1;
            &:before {
                content: "";
                background-color: $reds-1;
                display: inline-block;
                width: 1rem;
                height: 1rem;
                border-style: solid;
                border-width: 0.1rem;
                border-color: $neutrals-2;  
                border-radius: 50%;
            } 
        }
    }
}

