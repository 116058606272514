@import '../../../css/master.scss';

.Footer {
	background-color: $Footer-background-color;
	padding: $Footer-padding-top $Footer-padding-left;
	color: $Footer-text-color;
	a {
		color: $Footer-link-color;
		text-decoration: $Footer-link-decoration;
		&:hover {
			color: $Footer-hover-link-color;
			text-decoration: $Footer-hover-link-decoration;
		}
	}
}