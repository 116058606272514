@import "src/css/customizations/constants.scss";

body {
    background-color: $Body-background-color !important;
    color: $Body-text-color !important;
    font-size: $Body-font-size !important;
    font-family: $Body-font-family !important;
    max-width: 100%;
    overflow-x: hidden;

    main.hidden {
        display: none;
    }

    h1 {
        font-weight: $Header-font-weight-1;
        font-family: $Font-family-2;
        font-size: $Header-font-size-1;
    }

    h2 {
        font-weight: $Header-font-weight-2;
        font-size: $Header-font-size-2;
        font-family: $Font-family-2;
    }

    h3 {
        font-weight: $Header-font-weight-3;
    }

    h4 {
        font-weight: $Header-font-weight-4;
    }

    h5 {
        font-weight: $Header-font-weight-5;
    }

    strong {
        font-weight: $Strong-font-weight;
    }

    input {
        &:focus {
            box-shadow:$Input-focus-box-shadow-1;
            outline: 0;
        }
    }

    input[type="radio"] {
        &:focus {
            box-shadow:none;
            outline: 0;
        }
    }

    .required_field {
        margin-left: 0.25rem;
    }

    select {
        &:focus {
            box-shadow: $Select-focus-box-shadow-1;
            outline: 0;
        }
    }

    textarea {
        &:focus {
            box-shadow: $TextArea-focus-box-shadow-1;
            outline: 0;
        }
    }

    a {
        color: $Default-link-color;
        text-decoration: $Default-link-decoration;
        &:hover {
            color: $Default-hover-link-color;
            text-decoration: $Default-hover-link-decoration;
        }
        &:focus {
            box-shadow: $Link-focus-box-shadow-1;
            outline: 0;
        }
    }

    section.container {
        padding: $Container-section-padding-default;
        background-color: $Container-section-background-color-1;
    }

    .sidebar {
        align-items: right;
        justify-content: right;
    }

    .hide-offscreen {
        position: absolute;
        top: -1000px;
        left: -1000px;
        height: 1px;
        width: 1px;
    }

    .mobileClass {
        h1 {
            font-size: $Header-mobile-font-size-1;    
        }

        h2 {
            font-size: $Header-mobile-font-size-2;    
        }
    }

    button {
        outline: none !important;
    }

    .double-field-row {
        margin-left: 0;

        .double-field-left {
            padding-right: 0;
            margin-left: -1rem;
        }
        .double-field-right {
            padding-right: 0;
        }
    }

    .twirlygig {
        width: 100vw;
        height: 100vh;
        background-color: rgba(255, 255, 255, 0.8);
        padding: 50vh 50vw;
        position: absolute;
    }

    .card {
      border-radius: 0;
    }
}
.p-lg-5 {
    padding: 3rem !important;
}


.mobileClass {
    .double-field-row {
        margin-left: 0;

        .double-field-left {
            padding-right: 0;
            margin-left: -1rem;
        }
        .double-field-right {
            padding-right: 0;
            margin-left: 1rem;
        }
    }
}
