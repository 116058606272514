@import url('https://fonts.googleapis.com/css?family=Raleway%3A700%2Cregular%2C100%2C300%7CMontserrat%3A700%2Cregular%7CSource+Sans+Pro%3Aregular%2C300&subset=latin%2Clatin-ext%2Ccyrillic%2Ccyrillic-ext%2Cvietnamese%2Cgreek%2Cgreek-ext&ver=5.5.1');
@import url('https://fonts.googleapis.com/css?family=Roboto:400%7CRaleway:800');
@import url('https://fonts.googleapis.com/css2?family=Caveat&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Dawning+of+a+New+Day&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Sacramento&display=swap');

$Font-family-1: Raleway, sans-serif;
$Font-family-2: IntroBold, sans-serif;

$Header-font-weight-1: 700;
$Header-font-weight-2: 600;
$Header-font-weight-3: 600;
$Header-font-weight-4: 600;
$Header-font-weight-5: 600;
$Header-font-weight-6: 600;

$Header-font-size-1: 2.5rem;
$Header-font-size-2: 2rem;

$Header-mobile-font-weight-1: $Header-font-weight-1;
$Header-mobile-font-weight-2: $Header-font-weight-2;
$Header-mobile-font-weight-3: $Header-font-weight-3;
$Header-mobile-font-weight-4: $Header-font-weight-4;
$Header-mobile-font-weight-5: $Header-font-weight-5;
$Header-mobile-font-weight-6: $Header-font-weight-6;

$Header-mobile-font-size-1: 1.75rem;
$Header-mobile-font-size-2: 1.25rem; // Remains to be verified.

$Strong-font-weight: 600;
$Strong-mobile-font-weight: 600;

$Light-font-weight: 300;
$Light-mobile-font-weight: 300;

$Legal-disclaimer-font-size: 0.75rem;