@import 'src/css/master.scss';

.IAAInput {
    .checkboxContainer, .legendContainer {
        padding: 0 0.5rem;
    }
    
    .IAACheckbox {
        -webkit-appearance: none;
        display: inline-block;
        padding: 0.5rem; 
        position: relative;
        &:checked {
            background-color:$Checkbox-checked-background-color;
            color: $Checkbox-checked-text-color;
            &:after {
                content: '\2714';
                font-size: 1rem;
                position: absolute;
                top: -0.2rem;
                left: 0.1rem;
            }
        }
    }
}