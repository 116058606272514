@import 'src/css/master.scss';

.AppStepHeaderContainer {
    margin-bottom: 1rem;

    .AppStepHeader {
        font-size: 1.25rem;
        font-family: $Font-family-2;
        text-transform: uppercase;
    }
}

.mobileClass {
    .AppStepBlurb {
        margin-left: 0;
    }
}