.RegistrationSuccessful {
    section.container {
        max-width: 90%;
    }
}

.mobileClass {
    .RegistrationSuccessful {
        section.container {
            max-width: 100%;
        }
    }
}