@import 'src/css/master.scss';

.ProgressSubdrawer {
    position: relative;

    .EditableDetails {
        margin-bottom: 1rem;
    }

    .edit-link {
        position: absolute;
        right: 25px;
        top: 55px;
        font-size: 0.75rem;
        z-index: 999;

        a {
            color: $reds-1;
        }
    }

    .status-symbol {
        position: relative;

        .status-pipe {
            position: absolute;
            top: 25px;
            left: 9px;
            z-index: 9999;
        }
    }

    .title-text {
        margin-left: 1rem;
    }
}

.NavDrawerItem:last-of-type {
    .ProgressSubdrawer {
        .status-pipe {
            display: none;
        }
    }
}
 

.ProgressSubdrawer.dropdown-true {
    .status-pipe {
        display: none;
    }
    .DrawerToggle {
        box-shadow: none;
    }

}

.ProgressSubdrawer.dropdown-false {
    .edit-link {
        display: none;
    }
}
