#contact-us {
       h1.page-title {
              text-transform: uppercase;
              font-size:18px;
       }

       .white-container {
              padding:2.5rem;
              background-color: #fff;
              margin:0 0 30px 0;

              h3 {
                     font-size:18px;
                     font-weight:normal;
                     text-align: center;
              }

              p {
                     text-align: center;
                     max-width:300px;
                     margin:0 auto;
                     font-size:14px;
              }

              .btn {
                     background-color:#E14227;
                     color:#fff;
                     text-transform: uppercase;
                     font-weight: bold;
                     font-size:12px;
                     margin:25px auto 0;
                     width:200px;
                     display:block;
              }
       }

       .text-container {
              h2 {
                     text-transform: uppercase;
                     font-size:16px;
              }

              p {
                     font-size:14px;

                     &.legal-text {
                            font-size:10px;

                            a {
                                   color:red;
                                   text-decoration: underline;
                            }
                     }
              }
       }
}
