@import 'src/css/master.scss';

.MobileNoIncentivesAvailable {
    h1 {
        font-size: 1.5rem;
        margin-bottom: 1.75rem;
        color: $white;
        text-align: center;
    }
    .notify {
        margin-bottom: 2rem;
        text-align: center;
    }
    .NoIncentivesAvailableHead {
        padding: 2rem 1.5rem 2rem 1.5rem;
        background-color: $neutrals-1;
    }
    .NoIncentivesAvailableContent {
        padding: 2rem 1rem;
    }
}