@import 'src/css/master.scss';

.congratsModal {
    min-width: 700px;

    h2 {
        margin-top: 1em;
    }

    .text-bucket {
        padding: 3rem;
    }
}