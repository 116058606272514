.checklistUL {
    li {
        margin-left: -1rem;
    }
}

.mobileClass {
    .checklistUL {
        li {
            text-align: left;
        }
    }
}