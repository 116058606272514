@import 'src/css/customizations/primitives/colors.scss';

$Border-color-1: $neutrals-2;
$Border-width-1: 1px;
$Border-radius-1: 4px;

$Border-width-2: 4px;
$Border-radius-2: 4px;

$Border-1: $Border-width-1 solid $Border-color-1;
$Border-2: $Border-width-2 solid $Border-color-1; 

$Dashed-border-color-1: $neutrals-3;
$Dashed-border-width-1: 1px;
$Dashed-border-radius-1: 5px;

$Dashed-border-1: $Dashed-border-width-1 dashed $Dashed-border-color-1;