@import 'src/css/master.scss';

.TabletDashboardNav {
    padding: 1rem 0 !important;
    width: 120% !important;
    margin-left: -10% !important;

    .row {
        padding-bottom: 1rem;
    }

    .DashboardChargerHead {
        margin: 0;
        min-width: 100%;
        min-height: calc(100% - 56px);
    }

    .IAAIncentiveCard {
        min-width: 100%;
        box-shadow: 0px 20px 41px rgba(0, 0, 0, 0.1);
        min-height: 100%;

        .CardTitle {
            min-height: 2.5rem;
        }
    }

    .TabletModalLauncher {
        .launch-button {
            border: none;
            min-width: 100%;
            color: $reds-2;
            background-color: $neutrals-1;
            text-align: center;
            padding: 1rem 0;
            font-weight: $Strong-mobile-font-weight;
            font-family: $Font-family-2;
            text-transform: uppercase;
        }
    }
}

.tablet-profile-modal-body {
    // padding: 1rem 0 !important;

    .TabletModalProfileItem {
        .tablet-modal-profile-title {
            //width: 100%;
            background-color: $neutrals-5;
            margin-top: 0.5rem;
            margin-bottom: 0rem;
            font-weight: $Strong-mobile-font-weight;
            a {
                color: $reds-1;
            }
        }

        .NavDrawerItem {
            .EditableDetails {
                margin-top: 0.25rem;
            }
        }
    }
}
