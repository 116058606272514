@import 'src/css/customizations/primitives/colors.scss';

$Card-width: 285px;

$Mobile-card-width: 90%;

$Card-background-color: $white;

$Card-padding: 1.5rem 1rem;

$Card-font-size: 0.75rem;
