@import 'src/css/master.scss';

.DesktopNoIncentivesAvailable {
    width: 80%;
    margin: 0 auto;
    text-align: center;
    
    h1 {
        font-size: 2rem;
        margin-bottom: 1.75rem;
    }
    .notify {
        margin-bottom: 2rem;
    }
}