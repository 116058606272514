@import 'src/css/master.scss';

.EditableDetails {
    margin-top: 1.6rem;
    padding:0 0 15px 0;

    .EditableDetailsHeader {
        font-weight: $Strong-font-weight;
        text-transform: uppercase;
    }
    .EditableDetailsSubHeader {
        margin-left: 15px;
    }

    .edit-button, .save-button {
        button {
            border: none !important;
            background: none !important;
            font-weight: $Strong-font-weight;
            padding: 0;
        }
    }

    .edit-button {
        button {
            color: $reds-1;
        }
    }

    .save-button {
        button {
            color: $greens-1;
        }
    }

    .EditableDetail {
        margin-bottom:-5px;

        .label {
            font-size:0.75rem;
            color:$neutrals-1;
        }
        
        .value {
            font-size:0.75rem;
            font-weight:bold;
            color:$neutrals-1;
            overflow-wrap: break-word;
        }
    }
}

.DashboardNavDrawer {
    .EditableDetails {
        .EditableDetail {
            .label {
                padding: 0 0 0 1.5rem;
            }

            .value {
                padding: 0 1.5rem 0 0;
            }
        }
    }
}

.mobileClass {
    .EditableDetail {
        font-size: 14px;
        margin: 0;
    }
    .select-search {
        width: calc(100% - 2rem);
    }

    .mobile-edit-textfield {
        .inputLegend {
            margin-left: 1rem;
        }
        input {
            margin-top: 0;
            margin-left: 1rem;
            font-size: 1rem;
            line-height: 1.5rem;
            min-height: 3rem !important;
        }
        .IAAInput {
            margin: 0;

            input {
                margin-bottom: 0;
            }
        }
    }
}