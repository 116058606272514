@import 'src/css/master.scss';

.SubmitApplication {
    div {
        margin-bottom: 1.75rem;
    }

    .congrats {
        font-size: 1.25rem;
        font-weight: 600;
    }

    .strong, .congrats {
        font-weight: 600;        
    }

    .IAAButton {
        color: $reds-1;
        &:hover {
            color: white;
        }
    }

    .back-to-dashboard {
        padding-bottom: 6rem;
    }
}