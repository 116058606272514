@import 'src/css/customizations/primitives/colors.scss';

.Homepage {
  background-color: $white;

  section.container {
    background-color: transparent;
  }

  .Jumbotron {
    background-color: $neutrals-6;
    color: $neutrals-1;
    padding-bottom: 11em;
    margin-bottom: 0;
    border-radius: 0;
  }

  .CallToActionSection {
    margin-top: -9em;

    @media(min-width: 991px) {
      .error {
        position: absolute;
        bottom: 1rem;
      }
    }
  }

  .CheckZipcodeCard {
    @media(max-width: 991px) {
      .btn {
        width: 100%;
        display: block;
      }
    }
    .incentive {
      position: relative;
      padding-left: 2rem;
      img {
        position: absolute;
        left: 0;
      }
    }
  }
}

.CallToActionCard {
  .card-body {
    @media(min-width: 992px) {
      min-height: 22em;
    }

    .titles {
      flex-grow: 1;
    }
  }

  label {
    font-size: 0.875rem;
  }
}
