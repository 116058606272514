/*
Theme Name: TheGem Child
Description: Multi-Purpose Wordpress Theme
Author: Codex Themes.
Theme URI: http://codex-themes.com/thegem/
Author URI: http://codex-themes.com/thegem/
Template: thegem
Version: 1.0
Tags: one-column, two-columns, left-sidebar, right-sidebar, responsive-layout, custom-background, custom-colors, custom-header, custom-menu, featured-images, flexible-header, full-width-template, theme-options, translation-ready, dark, light
License: GNU General Public License
License URI: license.txt
*/

/**** Fonts ****/
/* @font-face {font-family: "IntroBold";src: url("/wp-content/themes/thegem-child/fonts/37C07C_0_0.eot");src: url("/wp-content/themes/thegem-child/fonts/37C07C_0_0.eot?#iefix") format("embedded-opentype"),url("/wp-content/themes/thegem-child/fonts/37C07C_0_0.woff2") format("woff2"),url("/wp-content/themes/thegem-child/fonts/37C07C_0_0.woff") format("woff"),url("/wp-content/themes/thegem-child/fonts/37C07C_0_0.ttf") format("truetype");} */

/*.panel-center { font-size: 19.2px ; line-height: 1.75; }*/
/*.breadcrumbs span[itemprop=title]:before { font-family: 'thegem-icons'; width: 20px; vertical-align: middle; padding: 0 12px; content: "\f015"; }*/
.page-title-block .breadcrumbs-container { background-color: #ffffff; padding-top: 12px; }
.page-title-block .breadcrumbs .current { padding-bottom: 2px; }
.page-title-title { display: none; }
/*@media (min-width: 980px) {
	#site-header .header-main:not(.header-layout-fullwidth_hamburger):not(.logo-position-menu_center) .site-title, #site-header .header-main:not(.header-layout-fullwidth_hamburger) .primary-navigation, #site-header .header-main:not(.header-layout-fullwidth_hamburger) #perspective-menu-buttons { display: table-cell; text-align: right; }
}*/
/* Dealer Page */
.filter-top { padding-top: 20px; border-top: 1px solid #ccc; }
.list-title:before { font-family: 'thegem-icons'; /*width: 25px; */vertical-align: middle; padding-right: 5px; content: '\e604'; color: #E14227; font-size: 20px; }
.dealer-information .list .left-block .list-content { padding-left: 25px; }
.certNote { margin-bottom: 30px !important; padding-bottom: 20px; border-bottom: 1px solid #ccc; }
.certNote p { font-size: small; line-height: 1.2; }
.certNote img { max-width: 30px; float: left; margin-right: 10px; margin-bottom: 20px; }
.certNote span { font-weight: bold; }

@media screen and (max-width: 500px) {
	.certNote p { line-height: 2; }
	#dealer-results .left-block .list-content, #dealer-results .right-block { text-align: center; }
}

/**** Colors ****/
/*  Veloz Orange: #E14227
	Orange: #f9943f
	Blue: #016fbb
	Green: #399933
	Sky Blue: #71cce4
	Light Blue: #d5eff7
	Grey: #ececec
*/
.text-white, .text-white h2, .text-white h3, .text-white h4 { color: #ffffff; }

.line1-5 { line-height: 1.5; }
.text-center { text-align: center; }

/**** Headings ****/
h1,h2,h3,h4,h5,h6 { letter-spacing: normal !important; }
h3, .title-h3 { margin-bottom: 1.4%; }
h4, .title-h4 { margin-bottom: 1.38983%; }
h5, .title-h5 { margin-bottom: 1%; }
.panel-center h2 { text-align: center; margin-bottom: 35px !important; } /*margin-bottom:1.38983% !important;*/
.panel-center h2:after { content: ""; display: block; height: 5px; width: 50px; margin: 10px auto 0; position: relative; z-index: 1; background: #f9943f; }
.panel-center .text-white h2:after { background: #ffffff; }
.panel-center .hbar-green h2:after { background: #399933; }
.panel-center .hbar-blue h2:after { background: #016fbb; }
.panel-center .hbar-skyblue h2:after { background: #71cce4; }
.panel-center .hbar-lightblue h2:after { background: #d5eff7; }
.panel-center .hbar-grey h2:after { background: #ececec; }
.panel-center .hbar-orange-veloz h2:after { background: #E14227; }

.page-title-title { position: relative; z-index: 3; }

/**** Links ****/
.text-white a { color: #ffffff; text-decoration: underline; }
.text-white a:hover { color: #ffffff; text-decoration: none; }

.gem-slideshow a { color: #ffffff; }
.gem-slideshow a:hover { color: #E14227; }

a.btn {
	font-family: "IntroBold";
    font-size: 125%;
    font-style: normal;
    font-weight: 600;
    background: #E14227;
    border: 1px solid #E14227;
    border-radius: 4px;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    outline: none;
    padding: .8125rem 1rem .5625rem;
    margin-top: 15px;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    -webkit-transition: all .2s cubic-bezier(.47,0,.75,.72);
    transition: all .2s cubic-bezier(.47,0,.75,.72);
    -webkit-appearance: none;
}
a.btn:hover { background: #fff; color: #E14227; border: 1px solid #E14227; }
.text-white a.btn { background-color: transparent; border-color: #ffffff; }
.text-white a.btn:hover { background-color: #ffffff; }
.panel-center a.btn, .gem-slideshow a.btn { font-size: 100%; }

/**** Lists ****/
.panel-center ul { list-style: disc; margin: 0 0 0 20px; padding: 0; }
.panel-center ul li, .panel-center .wpb_text_column ul li { padding: 0; list-style: disc; }
.panel-center .wpb_text_column ul li:before { position: relative; }

.list-box { padding: 30px 50px; }

/**** Top Area ****/
.top-area-menu .nav-menu > li.menu-item-gtranslate { padding: 0; }
/*.top-area-menu .nav-menu > li.menu-item-gtranslate a:before { content: ""; }*/
.top-area-menu .nav-menu > li.menu-item-gtranslate a[title|="Spanish"]:before { content: "|"; margin: 0 6px; font-size: 13px; }
html:lang(auto) li.menu-item-gtranslate a[title|="English"] { color: #FBEB71; font-weight: bold; }
html:lang(es) li.menu-item-gtranslate a[title|="Spanish"] { color: #FBEB71; font-weight: bold; }
html:lang(auto) li.menu-item-gtranslate a[title|="English"]:before, html:lang(es) li.menu-item-gtranslate a[title|="Spanish"]:before { color: #ffffff; font-weight: normal; }
.top-area-socials .socials { margin-top: 0; }
@media (min-width: 1212px) {
	.top-area .container { width: 100%; }
}
@media (max-width: 500px) {
	.top-area-menu .nav-menu { padding-bottom: 10px; }
	.top-area-alignment-right .top-area-items { text-align: center; }
}

/**** Page Title ****/
#page-title::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #666666;
    opacity: .2;

}

.page-id-24666 .page-title-block { background-position: 50% !important; }

@media (min-width: 1200px) {
	.page-id-24666 .block-content .container { padding-left: 0 !important; padding-right: 0 !important; }
}

@media (max-width: 767px) {
	.page-id-24666 .page-title-block { padding-bottom: 50px !important; }
} 


/***** Breadcrumbs *****/
.page-title-block .breadcrumbs-container { text-align: left; }

/**** Global Layout ****/
.block-content { padding: 0 !important; }
.wpb_row { margin-bottom: 0 !important; padding-top: 50px !important; padding-bottom: 50px !important; }
.wpb_row.vc_inner { padding-top: 0 !important; padding-bottom: 0 !important; }
.home .block-content { padding-top: 0; }
.iframe-container { border: 1px solid #ccc; display: block; margin-bottom: 5.08475%; overflow: hidden; padding-top: 62.5%; position: relative; }
iframe { width: 100%; height: 100%; border: 0; position: absolute; top: 0; left: 0; }

/**** Homepage ****/
#homegrid .grid-a { min-height: 610px; }
#homegrid .grid-b, #homegrid .grid-c { min-height: 300px; }
#homegrid .grid-d, #homegrid .grid-e, #homegrid .grid-f>div { min-height: 455px; }
#homebox, #homebox .grid-f { min-height: 455px; }

#homegrid h2 { font-family: Raleway, sans-serif; color: #ffffff; text-transform: uppercase; font-size: 50px; font-weight: 800; letter-spacing: normal; line-height: 1; margin-bottom: 0; text-align: left; }
#homegrid h2:after { display: none; }
#homegrid .grid-a h2 { font-size: 75px; }
#homegrid .h-grey h2 { color: #626262; }
#homegrid .h-bg-skyblue h2 { background-color: #71cce4; margin: -16px; padding: 18px; }
.grid-description { width: 38%; }

.grid-c a.btn { background: #ffffff; color: #E14227; }
.grid-c a.btn:hover { color: #626262; }
.grid-b a, .grid-c a { position: absolute; bottom: 20px; }
.grid-b a { right: 16px; }
.grid-c a { left: 16px; }
.refuel { position: absolute; bottom: 20px; text-align: center; width: 100%; margin-left: -1rem; }
a.btn.refuelE, a.btn.refuelH {}
a.btn.refuelE { margin-right: 20px; }
a.btn.refuelH {}

@media (min-width: 769px) {
	#homegrid .grid-f h2 { margin-top: 50px; }
}
@media (max-width: 768px) {
	#homegrid .grid-a { min-height: 310px; }
	#homegrid .grid-a h2 { font-size: 50px; }
}

/* Benefits Icons */
.benefits-icon .wpb_single_image { min-height: 70px; }

/**** Sponsor Grids ****/
#sponsor .contrib-title, #contributor .contrib-logo { display: none; }
.contrib-title { text-align: center; }
.contrib-logo img { margin: 0 auto; }

/**** Forms ****/
#zipcode-form #zipcode-input { height: 38px; border-radius: 4px; margin-right: 1rem; border: 1px solid #979797; font-size: 100%; padding: 0 15px; width: 300px; }
#zipcode-form #zipcode-submit { margin: 0; }

/**** Footer ****/
.footer-widget-area .widget_media_image { text-align: center; }
.textwidget img.veloz-logo { max-width: 175px; }
.footer-site-info { font-size: 80%; }

#menu-item-24355, #menu-item-24356, #menu-item-24357, #menu-item-24358 { display: none !important; } 
.ppt, .pp_details { display: none !important; }
.pp_content { height: auto !important; }


/***** Dealer/Vehicle Pages *****/
/*.list-title { padding-bottom: 15px; border-bottom: 1px solid #cccccc; }*/
.dealer-filter .list-title, .address-sort .list-title { font-size: 85%; margin-bottom: 15px; padding-bottom: 15px; border-bottom: 1px solid #cccccc; }
.savings .list-title, .electricity .list-title { max-width: 400px; /*float: left;*/ line-height: 2; margin-right: 40px; text-align: left; margin-bottom: 15px; /*margin-left: 40px; */}
.electricity .list-title { border-bottom: 1px solid #ffffff; }
.savings .list-title { border-bottom: 1px solid #cccccc; }
.savings .title-block form, .electricity .title-block form {text-align: left; }
.cLocation { color: #E14227; }
.savings #incentives-results { padding: 0; margin-top: 0; clear: both; }
.savings .dealerChange, .electricity .dealerChange { font-size: small; margin-bottom: 15px; }
.incentiveListprint { display: none; text-align: left;  }


/********** Vehicle Page **********/
/* Compare Function - Trigger */
.compare-row {
    width: span(12);
    display: flex;
    justify-content: center;
    margin: gutter() 0;

    .compare-tooltip {
        display: inline-block;
        margin-right: 1.0rem;
        position: relative;

        .compare-tooltip-box {
            width: 200px;
            background: rgba(0, 0, 0, 0.5);
            border-radius: 10px;
            color: #FFF;
            display: none;
            font-weight: 600;
            line-height: 1.25rem;
            margin: 10px 0 0 -100px;
            padding: 15px 10px;
            position: absolute;
                top: 100%;
                left: 50%;
            text-align: center;
            z-index: 1;
        }
    }
}

#compare-car-window {
    width: 100vw;
    height: 100vh;
    align-items: center;
    background: rgba(0, 0, 0, 0.1);
    display: none;
    padding: 0 100px;
    position: fixed;
        top: 0;
        left: 0;
    z-index: 9999;

    .compare-modal-window {
        width: span(9);
        background: #FFF;
        border: solid 1px #979797;
        border-radius: 10px;
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.36);
        margin: 0 auto;
        padding: 3.0rem 1.5rem;
        position: relative;
    }

    .modal-close {
        width: 30px;
        cursor: pointer;
        margin: 1.5rem 1.5rem 0 0;
        position: absolute;
            top: 0;
            right: 0;
    }

    h1 {
        @include title-font(2.0rem);
        text-align: center;
    }

    h2 {
        @include title-font(1.25rem);
        color: #E14227;
        text-align: center;
    }

    #car-info {
        display: flex;
        flex-flow: column nowrap;

        .car-row-clear,
        .car-row {
            width: span(12);
            display: flex;
            flex-flow: row nowrap;
            justify-content: center;
            padding: 1.0rem 1.5rem;

            .configure-button {
                font-size: 0.9rem;
                -webkit-appearance: none;
            }
        }

        .compare-col-3 {
            width: span(4);
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0 1.5rem;
            text-align: center;

            img { width: span(12); }
        }

        .compare-col-4 {
            width: span(3);
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0 1.5rem;
            text-align: center;

            img { width: span(12); }
        }
        .pic-col-3 { margin-left: span(4) }
        .pic-col-4 { margin-left: span(3) }
        .name-col-3 { margin-left: span(4) }
        .name-col-4 { margin-left: span(3) }
        .button-col-3 { margin-left: span(4) }
        .button-col-4 { margin-left: span(3) }
        .compare-header {
            font-weight: 600;
            justify-content: flex-start;
            line-height: 1.1875rem;
            text-align: left;
        }

        .car-row:nth-child(2n+2) {
            background: rgba(0, 0, 0, 0.04);
        }
    }
}

/* Incentive Function - Trigger */
#incentive-window {
    width: 100vw;
    align-items: center;
    background: rgba(0, 0, 0, 0.1);
    display: none;
    overflow-y: scroll;
    padding: 100px;
    position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    z-index: 9999;

    .incentive-modal-window {
        width: span(8);
        background: #FFF;
        border: solid 1px #979797;
        border-radius: 10px;
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.36);
        margin: 0 auto;
        padding: 3.0rem 1.5rem;
        position: relative;
    }

    .car-incentive-title { margin-top: 0.5rem; }

    .modal-close {
        width: 30px;
        cursor: pointer;
        margin: 1.5rem 1.5rem 0 0;
        position: absolute;
            top: 0;
            right: 0;
    }

    #tax-info {
        display: flex;
        flex-flow: column wrap;
        margin-top: gutter();

        .incentive-row {
            height: span(12);
            align-items: center;
            display: flex;
            margin: 0 1.5rem;
            padding: 12px;
            justify-items: center;

            .incentive-copy {
                width: span(12);
                display: flex;
                align-items: flex-start;

                .incentive-value {
                    width: span(4);
                    font-weight: 600;
                    padding-right: 6px;
                }

                .incentive-description {
                    width: span(8);
                    margin-top: -4px;
                    padding-left: 6px;

                    .incentive-title {
                        font-weight: 600;
                        line-height: 1.5rem;
                    }

                    .incentive-description-copy {
                        margin-top: 1.0rem;
                        line-height: 1.5rem;
                    }
                }
            }
        }

        .incentive-row:nth-child(2n+2) {
            background: rgba(0, 0, 0, 0.04);
        }
    }

    #tax-info,
    #charging-info,
    #electricity-info {
        margin-bottom: 2.0rem;
    }

    .incentive-header {
        color: #E14227;
        margin-bottom: 1.5rem;
    }

    .overall-incentive-row {
        display: flex;
        align-items: center;
        margin: 0 24px;
        padding: 2.0rem 12px;
        justify-items: center;

        .overall-incentive-copy {
            width: span(12);
            display: flex;
            align-items: flex-start;

            .overall-incentive-title {
                width: span(4);
                font-weight: 600;
                line-height: 1.5rem;
                padding-right: 6px;
            }

            .overall-incentive-desc {
                width: span(8);
                line-height: 1.5rem;
                padding-left: 6px;
            }
        }

        &:nth-child(2n+2) {
            background: rgba(0, 0, 0, 0.04);
        }
    }

    h1 {
        @include title-font(1.5rem);
        text-align: center;
    }

    h2 {
        @include title-font(1.25rem);
        margin: 1.0rem 0 2.0rem 0;
        text-align: center;
    }

    .disclaimer {
        font-style: italic;
        font-size: 0.875rem;
        line-height: 1.375rem;
        margin-top: 2.25rem;
        padding: 0 2.25rem;
    }
}

#car-list {
    width: span(12);
    display: flex;
    align-items: flex-start;
    flex-flow: row wrap;
    justify-content: flex-start;
    margin-top: gutter();

    &:focus {
        outline: none;
    }

    .vehicle {
        width: span(4);
        height: 568px;
        align-content: center;
        background: rgba(216, 216, 216, 0.5);
        border-radius: 21px;
        display: flex;
        flex-flow: column nowrap;
        margin: 0 ((33.33333% - span(4)) / 2) 33.33333% - span(4) ((33.33333% - span(4)) / 2);
        padding: 24px;

        .vehicle-name-row {
            display: flex;
            flex-flow: row nowrap;
            justify-content: space-around;

            .vehicle-name {
                width: span(11);
                height: 56px;
                flex: 0 1 span(11);
                font-size: 1.75rem;
                margin-bottom: 0.625rem;
            }

            #compare-form {
                width: span(1);
                height: 26px;
                flex: 0 1 span(1);
                margin-top: 3px;

                input[type="checkbox"] {
                    display: none;

                    &:checked + .heart-checkbox {
                    /* background-image: url(../images/compare-heart-checked.png);*/
                    }
                }

                .heart-checkbox {
                    height: 0;
                   /* background-image: url(../images/compare-heart-unchecked.png); */
                    background-position: center center;
                    background-repeat: no-repeat;
                    background-size: auto 100%;
                    cursor: pointer;
                    padding-left: 26px;
                    padding-top: 3px;
                }
            }
        }

        .vehicle-picture {
            width: span(12);
            height: 170px;
            text-align: center;

            img {
                height: span(12);
            }
        }

        .vehicle-fuel {
            width: span(3);
            display: flex;
            align-items: center;
            justify-content: flex-start;

            .bev, .fcev {
                height: 21px;
                margin: 0 6px 2px 0;
            }

            .phev {
                height: 15px;
                margin: 0 6px 2px 0;
            }
        }

        .vehicle-copy-row {
            display: flex;
            align-items: flex-end;
            flex-flow: row nowrap;

            .vehicle-cost {
                width: span(7);
                line-height: 1.15rem;

                .total-cost {
                    font-size: 2.0rem;
                    margin: 5px 0 -2px 0;
                }
            }

            .vehicle-seats {
                width: span(5);
                font-style: italic;
                padding-left: 1.0rem;
            }
        }

        .incentive-button-container {
            width: span(12);
            margin-top: 2.0rem;

            .incentive-button {
                width: span(7);
                background: rgba(0, 0, 0, 0.2);
                border: none;
                color: #FFF;
                cursor: pointer;
                display: inline-block;
                outline: none;
                padding: 0.5rem 0.875rem 0.3425rem 0.875rem;
                text-align: center;
                text-decoration: none;
                transition: all 200ms cubic-bezier(0.47, 0, 0.75, 0.72);
                -webkit-appearance: none;

                &:hover {
                    background: rgba(0, 0, 0, 0.4);
                    color: #FFF;
                }
            }
        }

        .vehicle-range-row {
            @include clearfix;
            display: flex;
            flex-flow: row nowrap;
            justify-content: space-around;
            margin-top: 2.0rem;

            .vehicle-electric-range {
                width: span(5);

                .total-range {
                    font-size: 1.5rem;
                    display: block;
                    margin-top: 2px;
                }
            }

            .vehicle-total-range {
                width: span(5);

                .total-miles {
                    font-size: 1.5rem;
                    display: block;
                    margin-top: 2px;
                }
            }

            .hov {
                width: span(2);
                text-align: right;

                img { height: 43px; }
            }
        }

        .configure-button {
            width: span(12);
            margin-top: gutter() * 2;
            align-self: flex-end;
            -webkit-appearance: none;
        }
    }
}

/*#car-list div[data-fuel-type=FCEV] .incentive-button { pointer-events: none; cursor: default !important; background-color: grey !important; color: #666666 !important; }*/

@media print {
	#page { background-color: #ffffff !important; }
	.car-disclaimer, #car-menu, #car-list, #colophon, #footer-socials, #footer-navigation, .scroll-top-button, #compare-car-window, #which-car #incentive-window .modal-close { display: none !important; }
	#which-car #incentive-window { width: 100%; height: 100%; padding: 0; }
	#which-car #incentive-window { position: relative; top: 0; right: auto; bottom: auto; right: auto; }
	#which-car #incentive-window .incentive-modal-window { width: 100% !important; padding: 0; border: none; box-shadow: none; }
	
	/*.container { padding: 0 50px; width: 100%; }*/
	#top-area, #site-header-wrapper, #page-title, .nav-container, .page-header, #zipcode-submit, .footer-left, .footer-nav, .panel .section-title:after, .list-title, .dealerChange { display: none !important; }
	article:before { content: "Electric for All | https://www.electricforall.org"; }
	.panel .section-title { font-size: 28px; }
	.incentive-header { font-size: 16px !important; line-height: 1.2 !important; margin-bottom: 5px; }
	.incentive { border-bottom: 1px solid rgba(0,0,0,.04); }
	.incentive:nth-of-type(odd) { background: none !important; }
	/*.content-row { width: 100%; max-width: 100%; margin: 0; }
	.incentive-saving, .incentive-info { float: none; width: 100%; }*/
	.incentive-saving { width: 30%; }
	.incentive-info { width: 68%; }
	#footer-new .footer-left, #footer-new .footer-right { display: block; width: 100%; float: none; }
	#footer-nav, #footer-new { background-color: #ffffff !important; padding: 0; margin: 0; color: #000000; }
	.footer-site-info, .footer-site-info a { color: #000000 !important; }
	#footer-new .footer-disclaimer { margin-top: 0; }
	a:link:after, a:visited:after { content:" " attr(href) " "; }
	p, .incentive-saving { font-size: 14px !important; line-height: 1.2 !important; }
	.incentiveListprint { display: block; float: left; text-align: left; width: 300px; margin: 0; }
	.savings .title-block form { float: left; max-width: 300px; }
	.savings #incentives-form #zipcode-input { border: none; font-size: 40px; height: 50px; font-family: IntroBold; padding: 0; }
	.savings #incentives-results { padding-top: 10px; }
}

