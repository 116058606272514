// Neutrals
$neutrals-1: #222222;
$neutrals-2: #6b7280;
$neutrals-3: #aeaeae;
$neutrals-4: #e5e5e5;
$neutrals-5: #ececec;
$white: #ffffff;
$neutrals-6: #FCFBFB;

// Yellows
$yellows-1: #ffff00;
$yellows-2: #d4af37;
$yellows-3: #eea40a;

// Reds
$reds-1: #E14227;
$reds-2: #ff8364;
$reds-3: #ffe6e0;

// Oranges
$orange-1: #FF4611;
$orange-2: #e14227;

// Greens
$greens-1: #4ca450;
$greens-2: #228b22;
$greens-3: #b9eab3;

// Blues
$blues-1: #0000ff;
$blues-2: #87ceeb;
$blues-3: #0047ab;

//Purple
$purples-1: #9f1b96;
