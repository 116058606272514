@import 'src/css/master.scss';

section.container.AppStepBar {
    padding-top: 0;
 }

.StepNumberHeader {
    width: 100%;
    color: $reds-1;
    text-transform: uppercase;
    font-weight: $Strong-font-weight;
}

.mobileClass {
    .ApplicationStepsNav {
        width: 85%;
        margin: 0 auto !important;
    }   
}