@import 'src/css/customizations/primitives/colors.scss';
@import 'src/css/customizations/primitives/fonts.scss';

@import 'src/css/customizations/elements/borders.scss';

// Button 1
$Button-background-color-1: $white;
$Button-text-color-1: $neutrals-1;
$Button-border-1: $Border-1;
$Button-hover-background-color-1: $reds-1;
$Button-hover-text-color-1: $white; 
$Button-hover-border-1: $Border-1;
$Button-border-radius-1: $Border-radius-1;
$Button-text-transform-1: uppercase;
$Button-padding-1: 4rem;
$Button-font-face-1: $Font-family-2;
$Button-font-weight-1: 700;

$Button-width-1: 160px;
$Button-height-1: 50px;

$Button-mobile-width-1: 100%;
$Button-mobile-height-1: 50px;

// Button 2
$Button-background-color-2: $greens-1;
$Button-text-color-2: $white;
$Button-border-2: $Border-1;
$Button-hover-background-color-2: $white;
$Button-hover-text-color-2: $greens-1; 
$Button-hover-border-2: $Border-1;
$Button-border-radius-2: $Border-radius-1;
$Button-text-transform-2: uppercase;
$Button-padding-2: 4rem;
$Button-font-face-2: $Font-family-2;
$Button-font-weight-2: 700;

$Button-width-2: 160px;
$Button-height-2: 50px;

$Button-mobile-width-2: 160px;
$Button-mobile-height-2: 50px;

// Button 3
$Button-background-color-3: $yellows-3;
$Button-text-color-3: $white;
$Button-border-3: $Border-1;
$Button-hover-background-color-3: $white;
$Button-hover-text-color-3: $yellows-3; 
$Button-hover-border-3: $Border-1;
$Button-border-radius-3: $Border-radius-1;
$Button-text-transform-3: uppercase;
$Button-padding-3: 4rem;
$Button-font-face-3: $Font-family-2;
$Button-font-weight-3: 700;

$Button-background-color-4: #F1F1F1;
$Button-text-color-4: #616161;

$Button-width-3: 160px;
$Button-height-3: 50px;

$Button-mobile-width-3: 160px;
$Button-mobile-height-3: 50px;
