@import 'src/css/master.scss';

.IAAInput {
    .inputLegend {
        font-weight: $Strong-font-weight;
    }
    // @TODO move padding and stuff to css constants
    .inputField, .select-search__input {
        margin: 0.75rem 0; 
        padding: 0.5rem 1rem;
    }
    .inputField, .select-search__input  {
        line-height: 2rem;
        border-radius: $Border-radius-1;
        border: $Button-border-1;
        height: 3rem;
    }

    .IAACheckbox {
        line-height: 2rem;
        border-radius: $Border-radius-1;
        border: $Button-border-1;
        height: 1.6rem;
    }

    .warning {
        color: $reds-1;
        font-weight: $Strong-font-weight;
    }
    
}
