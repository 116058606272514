@import 'src/css/master.scss';

.PhotoProcessor {
    .camera-case {
        margin: 0;
        border: $Dashed-border-1;
        border-radius: $Dashed-border-radius-1;

        .thumbnail {
            border: $Border-1;
            border-color: $neutrals-3;
            border-radius: $Dashed-border-radius-1;
            position: absolute;
            // The extra pixels accommodate the border of the container
            top: -1px;
            left: -1px;
            height: calc(100% + 2px);
            width: 63px;
        }

        .main-content {
          padding-left: 63px;
        }

        .tooltip-wrapper {
            padding: 16px;
        }

        .tooltip-placeholder {
            width: 21px; // @TODO Where do I get this? Trial & Error. Should be improved.
        }

        .btn.IAAButton {
          padding-left: 1rem;
          padding-right: 1rem;
        }
    }

    .PhotoProcessorInstrux {
        flex-grow: 1;

        .what-to-do {
            color: $neutrals-3;
        }
        .what-in-picture {
            font-weight: $Strong-font-weight;
        }
    }
}
.warning {
        color: $reds-1;
        font-weight: $Strong-font-weight;
        padding-top: 10px;
    }

.OCRPhotoProcessor {
    .camera-case {
        border-style: solid;
        background-color: $neutrals-4;

        .thumbnail {
            background-color: $white;
            margin-left: -3px;
        }

        .IAAButton {
            width: 100%;
        }
    }
}

.PhotoProcessor.mobileClass {
    margin-bottom: 1.25rem;

    .camera-case {
        border: $Dashed-border-1;
        min-height: 160px;
        position: relative;

        .PhotoProcessorInstrux {
            padding: 1rem 0;

            .what-to-do {
                text-transform: uppercase;
            }
        }

        .button-wrapper {

            margin: 0 !important;

            .IAAButton {
                max-width: 200px;
                padding: 0.75rem 1rem;
            }
        }

        .tooltip-wrapper {
            position: absolute;
            top: -10px;
            right: -5px
        }
    }
}
