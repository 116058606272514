// Box shadows
$Default-focus-box-shadow: 0 0 0 1px $neutrals-1;
$Card-box-shadow: 0px 20px 41px rgba(0, 0, 0, 0.1);
$Back-next-box-shadow: 20px 0px 41px rgba(0, 0, 0, 0.1);
$Back-next-box-shadow-mobile: 0px 20px 41px rgba(0, 0, 0, 0.1);

$Link-focus-box-shadow-1: $Default-focus-box-shadow;
$Link-focus-box-shadow-2: $Default-focus-box-shadow;
$Link-focus-box-shadow-3: $Default-focus-box-shadow;
$Link-focus-box-shadow-4: $Default-focus-box-shadow;
$Link-focus-box-shadow-5: $Default-focus-box-shadow;

$Input-focus-box-shadow-1: $Default-focus-box-shadow;
$Input-focus-box-shadow-2: $Default-focus-box-shadow;
$Input-focus-box-shadow-3: $Default-focus-box-shadow;

$Select-focus-box-shadow-1: $Default-focus-box-shadow;
$Select-focus-box-shadow-2: $Default-focus-box-shadow;
$Select-focus-box-shadow-3: $Default-focus-box-shadow;

$TextArea-focus-box-shadow-1: $Default-focus-box-shadow;
$TextArea-focus-box-shadow-2: $Default-focus-box-shadow;
$TextArea-focus-box-shadow-3: $Default-focus-box-shadow;
