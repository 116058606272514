@import 'src/css/customizations/primitives/colors.scss';
@import 'src/css/customizations/primitives/misc.scss';
@import 'src/css/customizations/elements/cards.scss';

$NavDrawer-Toggle-Width: $Card-width;
$NavDrawer-Toggle-Background-Color: $white;
$NavDrawer-Toggle-Text-Color: $neutrals-1;
$NavDrawer-Toggle-Border: none;
$NavDrawer-Toggle-Box-Shadow: $Card-box-shadow;
$NavDrawer-Toggle-Text-Transform: uppercase;
$NavDrawer-Toggle-Line-Height: 3rem;

$Drawer-Contents-Card-Spacing: 1rem;