@import 'src/css/master.scss';

section.howItWorks, section.whatToDo, .exampleBlurb {
    padding-bottom: 2rem !important;

    h2, .homePageNotLoggedInBlurb {
        padding-top: 3rem;
    }

    .homePageNotLoggedInBlurb {
        margin-top: -3px;

        .blurbHeadline {
            font-family: $Font-family-2;
            text-transform: uppercase;
            font-weight: $Strong-font-weight;
            margin: 0 auto 1rem auto;
        }
    }

    .twoLineSectionHeader {
        margin-top: -1.5rem;
    }

    & > .row {
      border-top: solid 1px $neutrals-3;
    }

    @media(max-width: 991px) {
      h2 {
        text-align: center;
        font-size: 1.5rem;
      }
    }
}

.mobileClass {
    h2 {
        margin-bottom: 2rem;
    }

    .homePageNotLoggedInBlurb {
        max-width: 80%;
        margin: 2rem auto;
    }
}

section.howItWorks {
    .homePageNotLoggedInBlurb {
        border-top: solid 5px $neutrals-1;
    }
}

.register-button {
    width: 80%;
    margin: 0 auto;
}

