@import 'src/css/customizations/primitives/colors.scss';
@import 'src/css/customizations/elements/body-defaults.scss';

$Header-background-color: $white;
$Header-color: $white;
$Header-link-color: $white;
$Header-navbar-toggler-border-color: $neutrals-5;
$Header-navbar-link-color: $white;
$Header-navbar-toggler-color: $white;
$Header-navbar-toggler-border-color: $neutrals-5;
$Header-border-bottom: 1px;