@import 'src/css/master.scss';

.inputDate {
    width: 100%;
}

.mobileClass {

    inputDate {
        padding: 8rem !important;
    }
}
