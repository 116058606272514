@import 'src/css/master.scss'; 

.IAAContent {
    background-color: $white;

    // should be in css/elements...
    padding: 3rem 5rem;

    section.container {
        background-color: $white;
        padding: $Fieldgroup-padding;
    }

    input {
        color: $neutrals-2;
    }

    .select-search {
        width: 100%;
    }

    .legalDisclaimer {
        font-size: $Legal-disclaimer-font-size;
        //margin: 1rem 0.5rem;
    }
}

.appAssistLayout.mobileClass {
    padding: 0;

    .AppStepHeader, .AppStepBlurb {
       display: block !important;
    }

    .NoIncentivesAvailableIAAClass {
        section.container {
            .IAABackNext{
                padding: 0rem 1rem;
            }
        }
    }

    .IAAContent {
        padding: 2rem 1rem;
        padding-bottom: 0;

        .IAAInput {
            padding: $Fieldgroup-padding;
        }

        section.container {
            padding: 0;
        }

        .row {
            margin: 0;
        }

        .application-steps-nav-wrapper {
            margin-bottom: 0.5rem;
            margin-top: -1rem;

            .ApplicationStepsNav {
                .app-step-tab {
                    padding: 0 3px;
                }

                .titleText {
                    display: none;
                }
                
                .inactiveStep {
                    border-color: $reds-3;
                }

                height: 1rem;
            }    
        }
    }

    .DashboardIAAClass, .NoIncentivesAvailableIAAClass {
        padding: 0;
        padding-bottom: 1rem;
    }

    .backNextButtons {
        margin-top: 1rem;
        box-shadow: none; 
        .left-button {
            padding-left: 0;
        }
    }

    .tablet-padding {
        padding: 1rem 0;
    }

}

.backNextButtons {
    // width: 100%;
    background-color: $white;
    // padding: 0.5rem 0;
    box-shadow: $Back-next-box-shadow;
    .right-button {
        // padding-right: 0;
    }
}