@import 'src/css/master.scss';
@import 'src/components/Atoms/Buttons/css/button-1.scss';

.IAAIncentiveCard {
    max-width: $Card-width;

    // background-color: $Card-background-color;

    font-size: $Card-font-size;

    // box-shadow: $Card-box-shadow;

    padding: $Card-padding;

    background: rgb(255,255,255);
background: -moz-linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 66%, rgba(226,226,226,1) 100%);
background: -webkit-linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 66%, rgba(226,226,226,1) 100%);
background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 66%, rgba(226,226,226,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#e2e2e2",GradientType=1);

    .CardTitle {
        text-transform: uppercase;
        font-weight: $Strong-font-weight;
     }

    .CardValue {
        font-size: 2rem;
        font-weight: $Strong-font-weight;
        padding-top: 1rem;
        padding-bottom: 1rem;
    }

    .CardDescription {
        width: 75%;

        margin: 0 auto;

        .collapsed {
            max-height: 3.5rem;
            overflow: hidden;
        }

        .SeeMoreSeeLess {
            font-weight: $Strong-font-weight;
        }
    }

    .CardBottom {
        padding-top: 1rem;

        .IAAButton {
            font-size: 1rem;
            margin-bottom: 1rem;
        }

        .IncentiveCardButton_available {
            .IAAButton {
                background-color: $Button-background-color-2;
                border: 1px solid $Button-background-color-2;
                color: $Button-text-color-2;
                &:hover {
                    background-color: $neutrals-3;
                    border: 1px solid $Button-hover-background-color-2;
                    color: $Button-hover-text-color-2;
                }
            }
        }

        .IncentiveCardButton_incomplete {
            .IAAButton {
                border: 1px solid $Button-background-color-3;
                background-color: $Button-background-color-3;
                color: $Button-text-color-3;
                &:hover {
                    border: 1px solid $neutrals-3;
                    background-color: $Button-hover-background-color-3;
                    color: $Button-hover-text-color-3;
                }
            }
        }

        .IncentiveCardButton_applied {
            .IAAButton {
                border: 1px solid $Button-background-color-4;
                background-color: $Button-background-color-4;
                color: $Button-text-color-4;
                &:hover {
                    cursor: auto;
                }
                &:not(:disabled):not(.disabled):active,
                &:not(:disabled):not(.disabled).active, .show > .btn-primary.dropdown-toggle {
                  background-color: $Button-background-color-4;
                  border: $Button-background-color-4;
                }
        
            }
        }
    }

    .StatusIcon {
        display: inline;
        padding-right: 0.25rem;
    }

    a {
        color: $reds-1;
        text-decoration: underline;
        &:hover {
            color: $reds-1;
            text-decoration: none;
        }
    }
}

.mobileClass {
    .IAAIncentiveCard {
        max-width: $Mobile-card-width;
        margin-left: auto;
        margin-right: auto;

        .IAAButton {
            width: $Button-mobile-width-2;
        }
    }
}
