@import 'src/css/master.scss';

.UserProfile {
    .SignatureBox, .SignatureBoxMobile {
        background-color: $neutrals-5 !important;

        .FontSelector {
            border: 1px solid $neutrals-2;
            border-radius: 4px;
            margin-top: 0.75rem;
            background-color: $white;
            width: 100%;
            padding: 1rem;

            .select-search {
                width: 90%;
                margin: 0.5rem auto;
            }

            .renderedSignature {
                height: 4.5rem;
                font-size: 2rem;
                line-height: 3rem;
                border-bottom: solid $neutrals-3 1px;
                width: 90%;
                margin: 0 auto;
                padding: 0.75rem 0;
            }

            .dropdownLabel {
              text-align: center;
              font-size: 0.8rem;
            }

            // "Dawning of a new day" is too long to render at 1rem;
            .select-search__input {
              font-size: 0.9rem;
            }
        }

        section.container {
            background-color: $neutrals-5 !important;
        }
    }

    .SignatureBox {
      margin: 0 -2.5rem;
      padding: 1rem 2.5rem;
    }

    .SignatureBoxMobile {
      padding: 0.5rem 1rem;
    }
}
