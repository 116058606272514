@import 'src/css/master.scss';

.IAAButton {
    text-transform: $Button-text-transform-1;
    font-weight: 700;
    font-family: $Font-family-2;
    font-size: 16px;
    &:hover {
        background-color: $Button-hover-background-color-1;
        color: $Button-hover-text-color-1;
        border: $Button-hover-border-1;
    }
    border-radius: $Button-border-radius-1;

    // May not work to set width and height here. Consider decoupling
    //width: 100%;
    //height: $Button-height-1;
    &.btn {
      &.btn-lg {
        font-size: 0.875em;
        padding: 0.75rem 2rem;
      }

      &.btn-primary {
        background-color: $reds-1;
        color: $white;
        border: 1px solid $reds-1;

        &:hover {
          background-color: darken($reds-1, 10%);
          border: 1px solid darken($reds-1, 10%);
          text-decoration: none;
        }
        &:not(:disabled):not(.disabled):active,
        &:not(:disabled):not(.disabled).active, .show > .btn-primary.dropdown-toggle {
          background-color: darken($reds-1, 15%);
          border: 1px solid darken($reds-1, 15%);
        }
      }

      &.btn-success {
        background-color: $greens-1;
        border: 1px solid $greens-1;

        &:hover {
          background-color: darken($greens-1, 5%);
          border: 1px solid darken($greens-1, 5%);
        }
        &:not(:disabled):not(.disabled):active,
        &:not(:disabled):not(.disabled).active, .show > .btn-primary.dropdown-toggle {
          background-color: darken($greens-1, 10%);
          border: 1px solid darken($greens-1, 10%);
        }
      }

      &.btn-light {
        border: 1px solid $neutrals-3;
      }
    }
}


.mobileClass {
    .IAAButton {
        //width: 100%;
        height: $Button-mobile-height-1;

        margin-bottom: 4px;
    }
}
